<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        Коммуникация{{ $t('communication-15-17.title') }}
      </h2>
      <h3>
        Методика «Один за всех и все за одного!». Субтест № 1. «Некорректная фраза»{{ $t('communication-15-17.methodology') }}
      </h3>
      <div class="collaboration-desc">
        {{ $t('communication-15-17.instruction') }}
      </div>

      <div class="d-flex flex-column">
        <div class="question-radio">
          1. Фраза «Надень(те) маску»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="А">
              А) Врач - пациенту
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="Б">
              Б) Мама - дочери
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="В">
              В) Медсестра - больному
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[0].choice" label="Г">
              Г) Режиссер - актёру
            </el-radio>
          </div>
        </div>
        <div class="question-radio">
          2. Фраза «Заверши отчет сегодня»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="А">
              А) Руководитель - сотруднику
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="Б">
              Б) Учитель – ученику
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="В">
              В) Ученик - однокласснику
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[1].choice" label="Г">
              Г) Сын –  отцу
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          3. Фраза «Что пожелаешь?»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="А">
              А) Бабушка - внучке
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="Б">
              Б) Отец - сыну
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="В">
              В) Продавец - клиенту
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[2].choice" label="Г">
              Г) Охранник – вору
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          4. Фраза «Ты красивая»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="А">
              А) Парень - девушке
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="Б">
              Б) Муж – жене
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="В">
              В) Сын – маме
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[3].choice" label="Г">
              Г) Студент – преподавателю
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          5. Фраза «Ты заслуживаешь быть первым»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="А">
              А) Тренер - спортсмену
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="Б">
              Б) Победитель - проигравшему
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="В">
              В) Фанат – кумиру
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[4].choice" label="Г">
              Г) Отец – сыну
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          6. Фраза «Принеси(те) мне воды»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="А">
              А) Авиапассажир - стюардессе
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="Б">
              Б) Гость – официанту
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="В">
              В) Пассажир – водителю
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[5].choice" label="Г">
              Г) Брат – сестре
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          7. Фраза «Благословите меня»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="А">
              А) Сын - отцу
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="Б">
              Б) Сын – бабушке
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="В">
              В) Внук – дедушке
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[6].choice" label="Г">
              Г) Отец – сыну
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          8. Фраза «Сохраняйте спокойствие»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="А">
              А) Учитель-ученикам
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="Б">
              Б) Библиотекарь - читателям
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="В">
              В) Сотрудник музея – посетителям
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[7].choice" label="Г">
              Г) Певец - публике
            </el-radio>
          </div>

        </div>

        <div class="question-radio">
          9. Фраза «Не оставляйте мусор»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="А">
              А) Сотрудник театра - зрителям
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="Б">
              Б) Арендодатель - клиентам
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="В">
              В) Гид - туристам
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[8].choice" label="Г">
              Г) Медсестра – хирургу
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          10. Фраза «Выключи телефон»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="А">
              А) Мать - ребенку
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="Б">
              Б) Тренер - спортсмену
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="В">
              В) Официант - гостю
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[9].choice" label="Г">
              Г) Отец – сыну
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          11. Фраза «Верни(те) мои деньги»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="А">
              А) Покупатель - продавцу
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="Б">
              Б) Кредитор - заемщику
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="В">
              В) Друг - подруге
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[10].choice" label="Г">
              Г) Заемщик – банку
            </el-radio>
          </div>
        </div>

        <div class="question-radio">
          12. Фраза «Поменяйте...»
          <div class="question-radio-list">
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="А">
              А) Покупатель - продавцу
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="Б">
              Б) Заказчик - портному
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="В">
              В) Гость - повару
            </el-radio>
            <el-radio v-model="answersIncorrectPhrase[11].choice" label="Г">
              Г) Студент – экзаменатору
            </el-radio>
          </div>
        </div>
      </div>


      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="openModal"
                 class="btn btn-danger">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendIncorrectPhraseResults(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      answersIncorrectPhrase: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
        { choice: "", question: "10" },
        { choice: "", question: "11" },
        { choice: "", question: "12" },
      ],
      score: null,
      correctAnswers: ["Г", "Г", "Г", "Г", "Б", "В", "Г", "Г", "Г", "В", "Б", "В"],
    }
  },
  methods: {
    validateAnswers() {
      return this.answersIncorrectPhrase.some(
          (answer) => answer.choice.trim() === ""
      );
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendIncorrectPhraseResults(timer) {

      if (!timer && this.validateAnswers()) {

        this.$bvModal.hide('exit-modal');

        return Vue.toastr({
          message: this.$t('error-title'),
          description: 'Ответьте на все вопросы',
          type: 'error'
        })
      }

      this.score = this.correctAnswers.reduce((total, correct, index) => {
        return total + (this.answersIncorrectPhrase[index].choice === correct ? 1 : 0);
      }, 0);

      const incorrectPhraseData = {
        score: this.score,
        answers: this.answersIncorrectPhrase,
      }

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      params.answer_id = {...params.answer_id, incorrectPhraseData}
      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))
      const quizToken = localStorage.getItem('quiz-token')

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'funny-stories')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      console.log('incorrectPhraseData', incorrectPhraseData)

      setTimeout(() => {
        this.$router.push({ path: '/funny-stories' })
      }, 1500)

    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerIncorrectPhrase', savedTimer);

        if (--timer < 0) {
          this.sendIncorrectPhraseResults(true)
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem('timerIncorrectPhrase')) {
      this.startTimer(localStorage.getItem('timerIncorrectPhrase'))
    } else {
      this.startTimer(300)
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }

    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
